import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE, NavigationPlugin } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { Badge, Button, IconEnum, MediumBoxShadow, ResponsivePXValue, SmallLoader, ZeroSpace } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useGetAppQuery } from '@hooks/api'

const Bar = styled.div<{ $standard: boolean }>`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props): string => props.$standard ? props.theme.colors.whites.pureWhite : props.theme.colors.whites.alabaster};

  ${(props): CSS => {
    if (props.$standard) {
      return MediumBoxShadow
    }
  }}

  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'flex', desktop: 'none' })}
  ${ResponsivePXValue('height', '56px')}
`

export const FlankContainer = styled.div`
  ${ResponsivePXValue('padding', '0 16px')}

  .menu-button {
    ${ZeroSpace}
  }
`

export const LoaderContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

export const LogoContainer = styled.a`
  ${ResponsivePXValue('height', '12px')}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Logo = styled.img`
`

interface MobileNavigationBarProps {
  showLogo?: boolean
  icon?: IconEnum
  variant?: 'standard' | 'menu'
  onIconClick?: () => void
  className?: string
}

export function MobileNavigationBar({ showLogo = true, className, icon = IconEnum.MENU, variant = 'standard', onIconClick }: MobileNavigationBarProps): JSX.Element {

  const config = useConfig()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const discountCode = appData?.app?.urlDiscount

  const _handleLogoClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    NavigationPlugin.shared().closeAllMenus()
    navigate('/')
  }

  const _handleMenuClick = (): void => {
    onIconClick?.()
  }

  const _handleCartClick = (): void => {
    CartPlugin.shared().open()
  }
  useEffect(() => {
    if (discountCode && discountCode?.length) {
      _handleCartClick()
    }
  }, [appData?.app?.urlDiscount])

  return (
    <Bar $standard={variant === 'standard'} className={className}>

      <FlankContainer>
        <If condition={!isNativeApplication}>
          <Button
            color='transparent'
            icon={icon}
            iconColor={theme.colors.greys.liteCodGrey}
            size='small'
            onClick={_handleMenuClick} />
        </If>
      </FlankContainer>

      <If condition={showLogo}>
        <LogoContainer onClick={_handleLogoClicked}>
          <Logo src='/logos/large.svg' />
        </LogoContainer>
      </If>
      <FlankContainer>
        <If condition={!isNativeApplication}>
          <Choose>
            <When condition={cartLoading}>
              <ButtonContainer>
                <LoaderContainer>
                  <SmallLoader color={theme.colors.oranges.coral} />
                </LoaderContainer>
              </ButtonContainer>
            </When>
            <Otherwise>
              <ButtonContainer onClick={_handleCartClick}>
                <Badge
                  count={cartData?.currentUser?.activeCart?.totalItems || 0}
                  offsetY={-10}
                  offsetX={20}
                  noShadow>
                  <Button
                    color='transparent'
                    icon={IconEnum.CART_OUTLINE}
                    iconColor={theme.colors.greys.liteCodGrey}
                    size='small'
                    onClick={_handleMenuClick} />
                </Badge>
              </ButtonContainer>
            </Otherwise>
          </Choose>
        </If>

      </FlankContainer>
    </Bar>
  )

}
